import {
  Box,
  Image,
  GridItem,
  Link,
  Icon,
  HStack,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { transitionTimingFunction } from './constants';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as PlayIcon } from './assets/play.svg';
import { ReactComponent as StopIcon } from './assets/stop.svg';
import { ReactComponent as InfoIcon } from './assets/info.svg';
import { ResponsivenessContext } from './ResponsivenessContext';
import { Tags } from './Tags';
import { MockContainer } from './MockContainer';

function CircleButton({
  shouldBecomeTransparent = true,
  icon,
  ...props
}) {
  return (
    <Flex
      align="center"
      justify="center"
      _active={{ transform: 'scale(0.93)' }}
      boxSize="40px"
      color={shouldBecomeTransparent ? '#fff' : 'black'}
      _groupHover={{ bg: '#fff', color: 'black' }}
      _groupFocus={{ bg: '#fff', color: 'black' }}
      transition={`all .2s ${transitionTimingFunction}`}
      p={1.5}
      bg={shouldBecomeTransparent ? 'rgba(255,255,255,0.2)' : 'white'}
      borderColor="gray.300"
      borderRadius="full"
      {...props}
    >
      {icon}
    </Flex>
  );
}

function FullContentBlock({
  shouldBeShown,
  children,
  fallbackBgColor,
  ...props
}) {
  return (
    <Box
      zIndex={2}
      flexDirection="column"
      opacity={shouldBeShown ? 1 : 0}
      pos="absolute"
      bottom={0}
      left={0}
      transition={`all .2s ${transitionTimingFunction}`}
      w="100%"
      h="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg={fallbackBgColor}
      {...props}
    >
      {children}
    </Box>
  );
}

export function ProjectCard({
  src, href, mock: Mock, fallbackBgColor, tags, name, description,
}) {
  const [openedSection, setOpenedSection] = useState(null);
  const isMockOpened = openedSection === 'mock';
  const isInfoOpened = openedSection === 'info';
  const { isMobile, gridColumnsAmount } = useContext(ResponsivenessContext);
  useEffect(() => {
    if (!isMobile && isMockOpened) setOpenedSection(null);
  }, [isMobile]);
  const shouldShowThumbnail = !openedSection;
  return (
    <GridItem
      bg="gray.200"
      overflow="hidden"
      data-group
      pos="relative"
      aspectRatio={gridColumnsAmount > 1 ? '1 / 1' : undefined}
      borderRadius="35px"
    >
      <HStack w="full" justify="space-between" zIndex={3} pos="absolute" p="20px">

        <CircleButton
          cursor="pointer"
          shouldBecomeTransparent={shouldShowThumbnail}
          onClick={() => { setOpenedSection(isMockOpened ? null : 'mock'); }}
          icon={
            <Icon boxSize="18px" as={isMockOpened ? StopIcon : PlayIcon} />
            }
        />
        <HStack>
          <CircleButton
            {...(isInfoOpened ? {
              bg: 'black', color: 'white', _groupHover: {}, _groupFocus: {},
            } : {})}
            onClick={() => setOpenedSection(isInfoOpened ? null : 'info')}
            cursor="pointer"
            shouldBecomeTransparent={shouldShowThumbnail}
            icon={<InfoIcon />}
          />
          <Link cursor="alias" isExternal href={href}>
            <CircleButton
              shouldBecomeTransparent={shouldShowThumbnail}
              icon={<ArrowIcon />}
            />
          </Link>

        </HStack>

      </HStack>
      {Mock ? <Box h="full" display={(isMockOpened || !openedSection) ? 'block' : 'none'}><Mock /></Box> : null}

      <FullContentBlock
        pointerEvents={isInfoOpened ? 'unset' : 'none'}
        shouldBeShown={isInfoOpened}
        fallbackBgColor="#E2E8F0"
        pos="relative"
        display={isInfoOpened ? 'block' : 'none'}
      >
        <MockContainer>

          <Text lineHeight="normal" fontSize="50px" fontWeight={600}>{name}</Text>
          <Text mt="4px" mb="8px">{description}</Text>
          <Tags items={tags} />

        </MockContainer>
      </FullContentBlock>

      <FullContentBlock
        _groupHover={{ opacity: 0 }}
        pointerEvents="none"
        _groupFocus={{ opacity: 0 }}
        fallbackBgColor={fallbackBgColor}
        shouldBeShown={shouldShowThumbnail}
      >
        <Image borderRadius="35px" src={src} objectFit="contain" h="full" />
      </FullContentBlock>
    </GridItem>
  );
}
