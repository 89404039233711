import {
  Box,
  HStack,
  Icon, Input,
} from '@chakra-ui/react';
import { useState } from 'react';
import { RBXUP_BRAND_COLOR } from './config';

const INPUT_BG = '#11151b';
export function RBXUPInput({
  icon, type, value: externalValue, onChange, placeholder, ...props
}) {
  const [value, setValue] = useState('');
  return (
    <HStack
      bg={INPUT_BG}
      borderRadius="10px"
      color="#fff"
      display="flex"
      _hover={{}}
      transition=".3s ease-in-out"
      outline="none"
      gap="12px"
      px="12px"
      border="1px solid #1e2228"
      _focusWithin={{ borderColor: RBXUP_BRAND_COLOR }}
      {...props}
    >
      <Box boxSize="23px" bg="hsla(0,0%,100%,.1)" borderRadius="7px" minW="23px" p="5px"><Icon display="block" boxSize="13px" as={icon} /></Box>
      <Input
        flexGrow={1}
        py="12px"
        px={0}
        outline="none!important"
        type={type}
        h="unset"
        fontFamily="'Montserrat Variable', sans-serif"
        fontSize="16px"
        _hover={{}}
        _placeholder={{
          color: 'inherit', opacity: 0.6,
        }}
        _focusVisible={{ boxShadow: 'none' }}
        border="none!important"
        value={externalValue || value}
        borderColor="transparent!important"
        onChange={onChange || ((e) => { setValue(e.target.value); })}
        placeholder={placeholder}
      />

    </HStack>
  );
}
