import { Button } from '@chakra-ui/react';
import { RBXUP_BRAND_COLOR, RBXUP_DEFAULT_BORDER_RADIUS } from './config';

export function RBXUPButton({ children, variant, ...props }) {
  let additionalStyles = {};
  if (variant === 'ghost') {
    additionalStyles = {
      bg: 'rgba(39, 113, 214, 0.12)',
      color: RBXUP_BRAND_COLOR,
      _active: {
        bg: 'rgba(39, 113, 214, 0.12)',
      },
      _hover: {
        bg: '#1f2f44',
      },
    };
  }
  return (
    <Button
      bg="#3e75c7"
      borderRadius={RBXUP_DEFAULT_BORDER_RADIUS}
      border="none"
      padding="14px 22px"
      fontFamily="'Montserrat Variable', sans-serif"
      lineHeight="normal"
      width="100%"
      color="#fff"
      height="unset!important"
      fontWeight={600}
      fontSize="16px"
      transition=".3s ease-in-out"
      _active={{
        bg: '#3f78cd',
        transform: 'scale(0.98)',
      }}
      _hover={{
        bg: '#4285eb',
      }}
      _disabled={{
        cursor: 'not-allowed', opacity: 0.6, _hover: { bg: '#3e75c7' }, _active: { transform: 'none' },
      }}
      {...additionalStyles}
      {...props}
    >
      {children}

    </Button>
  );
}
