import { Button as ChakraButton } from '@chakra-ui/react';
import { BLOXMART_buttonStyles } from './config';

export function BloxmartButton({ variant, ...props }) {
  let additionalProps;
  if (variant === 'brand') {
    additionalProps = {
      bg: 'rbxup.200',
      _hover: {
        backgroundColor: '#287eff',
      },
      _active: {
        transform: 'scale(0.97)',
        backgroundColor: '#0059de',
      },
    };
  }
  return <ChakraButton {...BLOXMART_buttonStyles} {...additionalProps} {...props} />;
}
