import { Global } from '@emotion/react';
import mabryRegularFont from './assets/fonts/MabryPro-Regular.ttf';
import mabryMediumFont from './assets/fonts/MabryPro-Medium.ttf';

export function Fonts() {
  return (
    <Global
      styles={`
      @font-face {
        font-family:'Mabry Pro';
        src:url('${mabryRegularFont}');
        font-weight: normal;
      }
      @font-face {
        font-family:'Mabry Pro';
        src:url('${mabryMediumFont}');
        font-weight: bold;
      }
      `}
    />
  );
}
