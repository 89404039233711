import '@fontsource-variable/rubik';
import {
  Avatar,
  Box, Divider, Flex, HStack, Image, VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

import { AccentTag } from './AccentTag';
import { QuantitySelector } from './QuantitySelector';
import { BloxmartText } from './Text';
import { BloxmartButton } from './Button';
import { MockContainer } from '../../MockContainer';
import { BLOXMART_ITEM_PRICE } from './config';
import partyPopper from './party-popper.png';
import whaleImg from './whale.png';

export function BloxmartInteractiveMock() {
  const [quantity, setQuantity] = useState(1);
  const [isBuying, setIsBuying] = useState(false);
  const [isBought, setIsBought] = useState(false);
  return (
    <MockContainer fontWeight={400} color="#96A0AF" fontFamily="'Rubik Variable', sans-serif" themeColor="#19222E">
      {!isBought ? (
        <>
          <AccentTag />
          <Flex mt="20px" gap="8px" direction="column">
            <BloxmartText fontSize="20px" fontWeight={500} color="rbxup.500">
              Virtual Nomad Bundle 💥 Automated transfer 💥
            </BloxmartText>
            <BloxmartText>Auto-transferring</BloxmartText>
          </Flex>
          <HStack mt="20px" spacing="16px">
            <Avatar bg="rbxup.300" p="10px" src={whaleImg} boxSize="46px" borderRadius="12px" />
            <Flex direction="column" gap="4px">
              <BloxmartText color="white" fontWeight={500}>
                Quirky Whale
              </BloxmartText>
              <BloxmartText>Merchant</BloxmartText>
            </Flex>
          </HStack>
          <Divider
            opacity={1}
            border="none"
            borderColor="#212d3d"
            borderStyle="solid"
            borderBottomWidth="1px"
            w="full"
            mt="20px"
            mb="15px"
            variant="primary"
          />
          <QuantitySelector currentQuantity={quantity} setQuantity={setQuantity} />
          <BloxmartButton
            onClick={() => {
              setIsBuying(true);
              setTimeout(() => {
                setIsBuying(false);
                setIsBought(true);
              }, 1500);
            }}
            isLoading={isBuying}
            mt="15px"
            variant="brand"
          >
            Buy for
            {' '}
            $
            {BLOXMART_ITEM_PRICE * quantity}
          </BloxmartButton>
        </>
      )
        : (
          <VStack spacing="17px">
            <Image boxSize="60px" src={partyPopper} />
            <Box>
              <BloxmartText textAlign="center" color="rbxup.500" fontWeight={500} fontSize="20px" display="block">
                The purchase was successful!
              </BloxmartText>
              <BloxmartText textAlign="center" mt="7px" fontSize="14px">
                The merchant has received your order.
              </BloxmartText>

            </Box>

            <BloxmartButton w="full" mt="3px" variant="brand" onClick={() => { setIsBought(false); setQuantity(1); }}>Buy more</BloxmartButton>

          </VStack>
        )}
    </MockContainer>
  );
}
