import { Box } from '@chakra-ui/react';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';

export function MockContainer({ children, themeColor, ...props }) {
  const ref = useRef();
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);
  const contentRef = useRef();
  useEffect(() => {
    if (!ref.current) return;
    const observer = new ResizeObserver((entries) => {
      const element = entries[0].target;
      const child = element.firstElementChild;
      const realScrollHeight = child.clientHeight - +(getComputedStyle(child).paddingBottom.slice(0, -2));
      const newIsScrollbarVisible = realScrollHeight > element.clientHeight;
      if (newIsScrollbarVisible !== isScrollbarVisible) setIsScrollbarVisible(newIsScrollbarVisible);
    });
    observer.observe(ref.current);
    return () => { observer.disconnect(); };
  }, [ref, isScrollbarVisible]);

  const content = useMemo(() => (
    <Box
      pos="relative"
      ref={contentRef}
      p="30px"
      h="fit-content"
      pt="80px"
      pb={!isScrollbarVisible ? '80px' : undefined}
      w="full"
    >
      {children}

    </Box>
  ), [children, isScrollbarVisible]);
  return (

    <Box
      ref={ref}
      overflowY="auto"
      bg={themeColor}
      sx={!isScrollbarVisible ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : undefined}
      w="full"
      h="full"
      {...props}
    >

      {content}

    </Box>

  );
}
