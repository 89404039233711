import {
  Heading, Text, Box, Grid, Icon, GridItem, Flex, Image,
} from '@chakra-ui/react';
import {
  useEffect, useState, useMemo, useRef,
} from 'react';
import cookieSrc from './assets/cookie.png';
import { ReactComponent as TSIcon } from './assets/typescript.svg';
import { ReactComponent as ReactIcon } from './assets/reactjs.svg';
import { ReactComponent as NodeIcon } from './assets/nodejs.svg';
import rbxupSrc from './assets/1.png';
import bloxmartSrc from './assets/2.png';
import { ProjectCard } from './ProjectCard';
import { Social } from './Social';
import { BloxmartInteractiveMock } from './projectsMocks/bloxmart';
import { ResponsivenessContext } from './ResponsivenessContext';
import { mobileBreakpointInPx } from './constants';
import { RBXUPMock } from './projectsMocks/rbxfail';

const data = [
  {
    name: 'Bloxmart',
    description: 'The ultimate Roblox marketplace. Discover rare items, stylish outfits, and more. Buy and sell items with ease using a rapid P2P-system. Unleash your imagination in the vibrant Roblox universe.',
    href: 'https://bloxmart.gg',
    src: bloxmartSrc,
    fallbackBgColor: '#000000',
    mock: BloxmartInteractiveMock,
    tags: ['react', 'ts', 'nodejs', 'chakra-ui'],
  }, {
    name: 'RBX.UP',
    description: 'A Robux e-shop with a fully operational ordering system and 100,000 clients in November, 2023.',
    href: 'https://rbxfail.io',
    fallbackBgColor: '#4A92FF',
    src: rbxupSrc,
    mock: RBXUPMock,
    tags: ['react', 'ts', 'nodejs'],
  },
];
export function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpointInPx);
  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${mobileBreakpointInPx}px)`);
    const listener = ({ matches }) => {
      setIsMobile(matches);
    };
    mediaQuery.addEventListener('change', listener);

    return () => mediaQuery.removeEventListener('change', listener);
  }, []);

  const gridRef = useRef();
  const [gridColumnsAmount, setGridColumnsAmount] = useState(3);
  useEffect(() => {
    const grid = gridRef.current;
    if (!grid) return;
    const callback = () => {
      const newGridColumnsAmount = window.getComputedStyle(grid).gridTemplateColumns.split(' ').length;
      if (newGridColumnsAmount !== gridColumnsAmount) setGridColumnsAmount(newGridColumnsAmount);
    };
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [gridRef]);
  const providerValue = useMemo(() => ({ isMobile, gridColumnsAmount }), [isMobile, gridColumnsAmount]);

  return (
    <>
      <Flex direction={{ sm: 'column', lg: 'row' }} justify={{ lg: 'space-between' }}>
        <Heading fontWeight={100} fontSize={{ sm: '5rem', md: '7rem' }} width="fit-content" lineHeight={1} textTransform="uppercase">Hi, I&apos;m Vlad.</Heading>
        <Box display={{ sm: 'none', lg: 'block' }}><Social /></Box>
      </Flex>

      <Box fontSize="xl">
        <Text>
          A full-stack engineer proficient in TypeScript
          {' '}
          <Icon verticalAlign="-0.1rem" as={TSIcon} />
          , ReactJS
          {' '}
          <Icon verticalAlign="-0.1rem" as={ReactIcon} />
          , and NodeJS
          {' '}
          <Icon verticalAlign="-0.1rem" as={NodeIcon} />
          .
        </Text>
        <Text>
          I&apos;m
          passionate about creating clean and efficient code that delivers great
          user experiences.

        </Text>
        <Text>
          I&apos;m a team player and believe that open
          communication and mutual respect are key to building successful
          partnerships.
        </Text>

      </Box>

      <Grid ref={gridRef} mt={5} gridTemplateColumns="repeat(auto-fit, minmax(340px, 1fr))" gap="10px">
        <ResponsivenessContext.Provider value={providerValue}>{data.map((d) => <ProjectCard {...d} />)}</ResponsivenessContext.Provider>
        <GridItem>
          <Flex minH="300px" bg="gray.200" borderRadius="35px" justify="center" align="center" aspectRatio={gridColumnsAmount > 1 ? '1 / 1' : undefined}>
            <Image boxSize="80px" src={cookieSrc} />
          </Flex>

        </GridItem>
      </Grid>
      <Box mt="20px" display={{ sm: 'block', lg: 'none' }}><Social /></Box>
    </>
  );
}
