import { Text as ChakraText } from '@chakra-ui/react';

export function BloxmartText({ children, ...props }) {
  return (
    <ChakraText
      fontWeight="inherit"
      color="inherit"
      fontSize="14px"
      lineHeight="normal"
      {...props}
    >
      {children}

    </ChakraText>
  );
}
