import '@fontsource-variable/montserrat';
import {

  Grid,
  GridItem,
  Icon, Text, VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ReactComponent as RobuxIcon } from './robux.svg';
import { ReactComponent as DollarIcon } from './dollar.svg';
import { ReactComponent as SuccessIcon } from './success.svg';
import { ReactComponent as CrownIcon } from './crown.svg';
import { ReactComponent as LineIcon } from './line.svg';
import { MockContainer } from '../../MockContainer';
import { RobuxRateBadge } from './RobuxRateBadge';
import { RBXUPFormLabel } from './FormLabel';
import { RBXUPInput } from './Input';
import { RBXUP_BRAND_COLOR, RBXUP_ROBUXES_LEFT, RBXUP_ROBUX_TO_DOLLAR_EXCHANGE_RATE } from './config';
import { RBXUPButton } from './Button';

const roundCurrencyValue = (value) => +(+value).toFixed(2);
const convertRobuxesToDollars = (robuxes) => roundCurrencyValue(robuxes / RBXUP_ROBUX_TO_DOLLAR_EXCHANGE_RATE);
function RBXUPHeading({ children, ...props }) {
  return (
    <Text color="white" fontSize="25px" fontWeight={600} {...props}>{children}</Text>
  );
}
export function RBXUPMock() {
  const [isMockingLoading, setIsMockingLoading] = useState(false);
  const [page, setPage] = useState('home');
  const [robuxesAmount, setRobuxesAmount] = useState('');
  const [dollarsAmount, setDollarsAmount] = useState('');
  const isTooBigRobuxesAmount = robuxesAmount > RBXUP_ROBUXES_LEFT;
  let content;
  switch (page) {
    case 'home':
      content = (
        <>
          <RBXUPHeading fontWeight={700} fontSize="30px" lineHeight="1.4">
            <Text as="span" pos="relative">
              T
              <Icon top="4px" left="-5.5px" transform="rotate(-30deg)" boxSize="10px" as={CrownIcon} pos="absolute" />
            </Text>
            he best Robux store for
            {' '}
            <Text as="span" color={RBXUP_BRAND_COLOR} pos="relative">
              you
              <Icon as={LineIcon} pos="absolute" height="unset" bottom="-10px" w="full" left={0} />
            </Text>
            {' '}
            and your friends
          </RBXUPHeading>
          <Text mt="15px" lineHeight="1.75">Our store offers you the best conditions and security of transactions. Buy game currency at a favorable rate and enjoy the game!</Text>
          <RBXUPButton mt="12px" onClick={() => setPage('buy')} w="fit-content">Buy Robuxes</RBXUPButton>
        </>
      );
      break;
    case 'buy':
      content = (
        <>

          <RobuxRateBadge />
          <Text mt="4px" fontWeight={600} color="white" fontSize="25px">
            Buy Robuxes
          </Text>
          <RBXUPFormLabel>
            R$
            {' '}
            <Text as="span" color={RBXUP_BRAND_COLOR}>{RBXUP_ROBUXES_LEFT}</Text>
            {' '}
            available.
          </RBXUPFormLabel>
          <Grid gap="13px" mt="15px" templateColumns="repeat(auto-fit, minmax(200px, 1fr))">
            <GridItem>
              <RBXUPFormLabel>Your desired R$ amount</RBXUPFormLabel>

              <RBXUPInput
                value={robuxesAmount}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setRobuxesAmount(newValue);
                  setDollarsAmount(convertRobuxesToDollars(newValue || 0));
                }}
                type="number"
                placeholder="5000"
                icon={RobuxIcon}
              />
              {isTooBigRobuxesAmount && <Text mt="5px" fontSize="14px" color="rgb(255, 93, 93)">The entered amount is bigger than the available amount.</Text>}
            </GridItem>
            <GridItem>
              <RBXUPFormLabel>Total price in $US</RBXUPFormLabel>
              <RBXUPInput
                value={dollarsAmount}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setDollarsAmount(newValue ? roundCurrencyValue(newValue) : '');
                  setRobuxesAmount(
                    roundCurrencyValue(newValue * RBXUP_ROBUX_TO_DOLLAR_EXCHANGE_RATE),
                  );
                }}
                type="number"
                placeholder={convertRobuxesToDollars(5000)}
                icon={DollarIcon}
              />
            </GridItem>

          </Grid>
          <RBXUPButton
            onClick={() => {
              setIsMockingLoading(true);
              setTimeout(() => { setIsMockingLoading(false); setPage('bought'); setRobuxesAmount(''); setDollarsAmount(''); }, 1500);
            }}
            mt="20px"
            isLoading={isMockingLoading}
            isDisabled={!+dollarsAmount || isTooBigRobuxesAmount}
          >
            Pay and Receive R$
          </RBXUPButton>
        </>
      );
      break;
    case 'bought':
      content = (
        <VStack>

          <Icon boxSize="78px" as={SuccessIcon} />

          <Text fontWeight={600} fontSize="23px" color="white" textAlign="center">Thanks for your purchase!</Text>
          <Text textAlign="center">Robuxes will be sent to your Roblox account in a couple of seconds.</Text>
          <Grid w="full" mt="8px" gap="10px" templateColumns="repeat(auto-fit, minmax(200px, 1fr))">
            <GridItem><RBXUPButton variant="ghost" onClick={() => setPage('home')}>Home</RBXUPButton></GridItem>
            <GridItem>
              {' '}
              <RBXUPButton onClick={() => { setPage('buy'); }}>Buy More</RBXUPButton>
            </GridItem>
          </Grid>
        </VStack>
      );
      break;
  }
  return (
    <MockContainer
      fontWeight={500}
      color="hsl(0deg 0% 95.91% / 75%)"
      themeColor="#0E1217"
      fontFamily="'Montserrat Variable', sans-serif"
    >
      {content}
    </MockContainer>
  );
}
