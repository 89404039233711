export const BLOXMART_LEFT_IN_STOCK = 29;
export const BLOXMART_ITEM_PRICE = 100;
export const BLOXMART_buttonStyles = {
  fontWeight: 500,
  borderRadius: '12px',
  _disabled: { pointerEvents: 'none' },
  color: 'white',
  minW: 0,
  p: '16px 20px!important',
  textTransform: 'uppercase',
  _active: {
    transform: 'scale(0.97)',
  },
  backgroundColor: 'rbxup.400',
  _hover: {
    backgroundColor: 'rbxup.300',
  },
  _loading: {
    opacity: 0.6,
  },
  height: 'auto!important',
  fontSize: '14px',

};
