import { IconButton as ChakraIconButton } from '@chakra-ui/react';
import { BLOXMART_buttonStyles } from './config';

export function BloxmartIconButton(props) {
  return (
    <ChakraIconButton
      {...BLOXMART_buttonStyles}
      {...props}
    />
  );
}
