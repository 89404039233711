import {
  Box,
  Wrap, WrapItem,
} from '@chakra-ui/react';

const tagsData = {
  ts: {
    label: 'TypeScript',
    color: '#fff',
    background: '#007ACC',
  },
  react: {
    label: 'ReactJS',
    color: '#fff',
    background: '#0A7EA4',
  },
  nodejs: {
    label: 'NodeJS',
    color: '#000',
    background: '#8cc84b',
  },
  'chakra-ui': {
    label: 'Chakra UI',
    color: '#fff',
    background: '#319795',
  },
};
export function Tags({ items }) {
  return (
    <Wrap spacing="5px">
      {items.map((tagName) => {
        const tagData = tagsData[tagName];
        if (!tagData) throw new Error(`${tagName} not support in ./Tags.jsx`);
        return (
          <WrapItem>
            <Box cursor="default" fontSize="13px" px="9px" py="2px" borderRadius="full" color={tagData.color} background={tagData.background}>{tagData.label}</Box>
          </WrapItem>
        );
      })}
    </Wrap>
  );
}
