// import { checkboxAnatomy, selectAnatomy } from '@chakra-ui/anatomy';
import {
  extendTheme,
  defineStyleConfig,
//   createMultiStyleConfigHelpers,
} from '@chakra-ui/react';

const Link = defineStyleConfig({
  baseStyle: {
    cursor: 'alias',
    _hover: {
      textDecor: 'none',
    },
  },

});
export const theme = extendTheme({
  breakpoints: {
    sm: '0px',
    md: '670px',
    lg: '840px',
    xl: '1030px',
    '2xl': '1536px',
  },
  styles: {
    global: {
      body: {
        lineHeight: 1.6,
        bg: '#f9f9f9',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
      '#root': {
        p: 5,
        maxW: '1600px',
        margin: '0 auto',
        alignItems: 'center',
      },
    },
  },
  fonts: {
    body: "'Mabry Pro',sans-serif",
    heading: "'Mabry Pro', sans-serif",
  },
  colors: {
    rbxup: {
      200: '#0066ff',
      300: '#2e3744',
      400: '#252E3C',
      500: '#EBEFFD',
      600: '#1F2937',
    },
  },
  components: {
    Link,
  },
});
