import { Icon, Tag } from '@chakra-ui/react';
import { BloxmartText } from './Text';
import { ReactComponent as TickIcon } from './tick.svg';
import { BLOXMART_LEFT_IN_STOCK } from './config';

export function AccentTag() {
  return (
    <Tag w="fit-content" px="15px" py="10px" bg="linear-gradient(0deg, rgba(19, 231, 142, 0.05) 0%, rgba(19, 231, 142, 0.05) 100%), rgba(38, 51, 67, 0.80)" color="rgba(19, 231, 142, 1)">
      <Icon boxSize="14px" as={TickIcon} />
      <BloxmartText ml="9px">
        {BLOXMART_LEFT_IN_STOCK}
        {' '}
        pcs. left
      </BloxmartText>
    </Tag>

  );
}
