import {
  Box, HStack,
} from '@chakra-ui/react';
import { ReactComponent as MinusIcon } from './minus.svg';
import { ReactComponent as PlusIcon } from './plus.svg';

import { BloxmartText } from './Text';
import { BLOXMART_ITEM_PRICE, BLOXMART_LEFT_IN_STOCK } from './config';
import { BloxmartIconButton } from './IconButton';

export function QuantitySelector({ currentQuantity, setQuantity }) {
  return (
    <HStack mt="15px" spacing="10px">
      <HStack spacing="5px" color="white">
        <BloxmartIconButton
          onClick={() => setQuantity(currentQuantity - 1)}
          borderRadius="5px"
          p="7px"
          boxSize="34px"
          color="white"
          isDisabled={currentQuantity <= 1}
          aria-label="Удалить 1 штуку"
          icon={<MinusIcon />}
        />
        <Box
          py="6px"
          px="12px"
          borderRadius="5px"
          border="1px solid #2e3744"
          borderColor="primary.400"
        >
          <BloxmartText
            fontSize="16px"
            color="white"
            bg="transparent"
            fontWeight={500}
            height="auto"
            value={currentQuantity}
          >
            {currentQuantity}

          </BloxmartText>
        </Box>
        <BloxmartIconButton
          onClick={() => setQuantity(currentQuantity + 1)}
          borderRadius="5px"
          p="7px"
          boxSize="34px"
          color="white"
          isDisabled={currentQuantity >= BLOXMART_LEFT_IN_STOCK}
          aria-label="Добавить 1 штуку"
          icon={<PlusIcon />}
        />
      </HStack>
      <Box bg="rbxup.600" py="7.5px" px="10px" borderRadius="6px">
        <BloxmartText color="white" fontSize="16px" fontWeight={500}>
          $
          {' '}
          {BLOXMART_ITEM_PRICE * currentQuantity}
        </BloxmartText>
      </Box>

    </HStack>
  );
}
