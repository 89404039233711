import { Flex, Link, Box } from '@chakra-ui/react';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';

function StyledLink({ href, children, colors }) {
  return (
    <Link
      href={href}
      pos="relative"
      isExternal
      display="flex"
      justifyContent="center"
      flex={1}
      w="fit-content"
      _hover={{ transform: 'scale(0.96)', bgPos: 'top left 100%' }}
      data-group
      bgImage={`linear-gradient(to right, #000, #000 33.33333%, ${colors[0]} 66.66666%,${colors[1]})`}
      bgSize="300% 100%"
      bgPos="top left"
      transition="transform .2s, background-position .5s"
      transitionTimingFunction="ease-in-out"
      bgClip="text"
      sx={{ '-webkit-text-fill-color': 'transparent' }}
    >
      {children}
      <Box display="inline-block" color="black">
        <ArrowIcon
          style={{
            verticalAlign: '-0.15rem', transform: 'rotate(45deg) translateX(-1px)',
          }}
          width="1.35rem"
          height="1.35rem"
        />

      </Box>
    </Link>
  );
}

const socialData = [{
  href: 'https://github.com/extrapoisonouscake',
  label: 'GitHub',
  colors: ['hsla(0, 0%, 17%, 1)', 'hsla(0, 0%, 69%, 1)'],
}, {
  href: 'https://linkedin.com/in/vladislavgubarev',
  label: 'LinkedIn',
  colors: ['#0077B5', '#0077B5'],
}, {
  href: 'https://tomato-gayla-85.tiiny.site',
  label: 'Résumé',
  colors: ['#b89bb8', '#6a7ec5'],
}];

export function Social() {
  return (
    <Flex wrap="wrap" mt={{ sm: 5, lg: 0 }} justify={{ sm: 'space-around', lg: 'initial' }} direction={{ lg: 'column' }} fontSize="xl">
      {socialData.map(({ href, label, colors }) => <StyledLink key={href} href={href} colors={colors}>{label}</StyledLink>)}
    </Flex>
  );
}
