import { Box, Text } from '@chakra-ui/react';
import balanceBg from './balance_bg.svg';
import { RBXUP_BRAND_COLOR, RBXUP_ROBUX_TO_DOLLAR_EXCHANGE_RATE } from './config';

function getOneDollarPrice(robuxesInDollarRate) {
  return Number(robuxesInDollarRate).toFixed(2);
}

export function RobuxRateBadge() {
  return (
    <Box
      borderRadius="full"
      py="3px"
      w="fit-content"
      px="12px"
      bgImage={`url(${balanceBg})`}
      bgSize="110% 150%"
      bgPos="0 -12px"
      bgColor="rgba(39,113,214,.12)"
    >
      <Text
        textAlign="center"
        fontSize="13px"
        color={RBXUP_BRAND_COLOR}
      >
        <Text as="span">1 $ =&nbsp;</Text>
        {getOneDollarPrice(RBXUP_ROBUX_TO_DOLLAR_EXCHANGE_RATE)}
        {' '}
        R$
      </Text>

    </Box>
  );
}
